<template>
  <div class="jetlinks-contact">
    <div class="banner">
      <h1><span>JetLinks</span></h1>
      <p>设备管理，多协议适配，数据收集，规则引擎，快速构建物联网相关业务系统</p>
    </div>
    <jet-card title="合作伙伴招募" subTitle="科技需要展示的舞台，共赢需要你我协作！" :titleType="2">
      <div class="container enlist">
        <a-row class="benefits" type="flex" justify="space-around">
          <a-col :span="6">
            <div class="benefits-item">
              <div class="check"><a-icon type="check" style="color: #24B276; font-size: 1.5rem;"/></div>
              <p>创新开拓集思广议相信我们，共同研发</p>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="benefits-item">
              <div class="check"><a-icon type="check" style="color: #24B276; font-size: 1.5rem;"/></div>
              <p>依托先进平台让创新领先一步，合作建立共赢</p>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="benefits-item">
              <div class="check"><a-icon type="check" style="color: #24B276; font-size: 1.5rem;"/></div>
              <p>代理硬件申请</p>
            </div>
          </a-col>
        </a-row>
        <div class="form-content">
          <h4>选择您感兴趣的服务类型：</h4>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <div class="service-type">
                <div class="type-item" :class="{active: applicantType == 0}" @click="chooseType(0)">
                  <a-icon type="check" style="color: #FFFFFF; font-size: 1.2rem;position: absolute;right: 0.2rem;top: 0.2rem;z-index: 5;"/>
                  <img src="@/assets/images/contact/service3.png" alt="">
                  <h3>加入我们</h3>
                </div>
                <div class="type-item" :class="{active: applicantType == 1}" @click="chooseType(1)">
                  <a-icon type="check" style="color: #FFFFFF; font-size: 1.2rem;position: absolute;right: 0.2rem;top: 0.2rem;z-index: 5;"/>
                  <img src="@/assets/images/contact/service1.png" alt="">
                  <h3>项目合作</h3>
                </div>
                <div class="type-item" :class="{active: applicantType == 2}" @click="chooseType(2)">
                  <a-icon type="check" style="color: #FFFFFF; font-size: 1.2rem;position: absolute;right: 0.2rem;top: 0.2rem;z-index: 5;"/>
                  <img src="@/assets/images/contact/service2.png" alt="">
                  <h3>硬件代理</h3>
                </div>
                <!-- <div class="type-item" :class="{active: form.applicantType == 4}" @click="chooseType(4)">
                  <a-icon type="check" style="color: #FFFFFF; font-size: 1.2rem;position: absolute;right: 0.2rem;top: 0.2rem;z-index: 5;"/>
                  <img src="@/assets/images/contact/service4.png" style="margin-bottom: 1.5rem">
                  <h3>其他</h3>
                </div> -->
              </div>
            </a-form-item>
            <div v-if="applicantType == 0">
              <p>我们热烈欢迎每一位想立足物联网行业的有志之士，与公司共同进步！</p>
              <a-form-item class="custom-require">
                <a-input placeholder="姓名" v-decorator="['applicantName', { rules: [{ required: true, message: '请输入您的姓名!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="毕业院校" v-decorator="['graduateInstitution', { rules: [{ required: true, message: '请输入您的毕业院校!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="专业" v-decorator="['major', { rules: [{ required: true, message: '请输入您的专业!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="联系电话" v-decorator="['phone', { rules: [{ required: true, message: '请输入您的联系电话!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="期望应聘职位" v-decorator="['jobObjective', { rules: [{ required: true, message: '请输入您的期望应聘职位!' }] }]"></a-input>
              </a-form-item>
              <p>填写完以上基本信息，请把简历发送至邮箱：resume@jetlinks.cn</p>
            </div>
            <div v-if="applicantType == 1">
              <p>如果您有实际业务场景需求，需要基于JetLinks进行开发，进行项目合作，加入Q群，认准并联系商务管理员</p>
              <a-form-item class="custom-require">
                <a-input placeholder="申请人姓名" v-decorator="['applicantName', { rules: [{ required: true, message: '请输入申请人姓名!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="公司名称" v-decorator="['company', { rules: [{ required: true, message: '请输入公司名称!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="申请人职位" v-decorator="['position', { rules: [{ required: true, message: '请输入申请人职位!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="申请人电话" v-decorator="['phone', { rules: [{ required: true, message: '请输入申请人电话!' }] }]"></a-input>
              </a-form-item>
              <a-form-item>
                <a-textarea placeholder="项目概况/需求合作内容" v-decorator="['projectOverview', { rules: []}]" :rows="4" />
              </a-form-item>
            </div>
            <div v-if="applicantType == 2">
              <p>辅助推广硬件厂商20+，帮助硬件厂商实现自有价值，如果您看重并信任JetLinks接入能力，我们也真诚邀请各类硬件厂商与我们达成合作，通过JetLinks行业渠道，打开硬件市场</p>
              <a-form-item class="custom-require">
                <a-input placeholder="厂商名称" v-decorator="['manufacturer', { rules: [{ required: true, message: '请输入厂商名称!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="联系人姓名" v-decorator="['linkman', { rules: [{ required: true, message: '请输入联系人姓名!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="联系人电话" v-decorator="['phone', { rules: [{ required: true, message: '请输入联系人电话!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="硬件设备类型" v-decorator="['hardwareType', { rules: [{ required: true, message: '请输入硬件设备类型!' }] }]"></a-input>
              </a-form-item>
              <a-form-item class="custom-require">
                <a-input placeholder="硬件运用场景" v-decorator="['applicationScenarios', { rules: [{ required: true, message: '请输入硬件运用场景!' }] }]"></a-input>
              </a-form-item>
              <a-form-item>
                <a-textarea placeholder="留下更多详细信息，以便我们更好的帮助您……" :rows="4" />
              </a-form-item>
            </div>
            <a-button style="width: 100%;height: 3.75rem;" type="primary" html-type="submit">马上提交</a-button>
          </a-form>
        </div>
      </div>
    </jet-card>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from '../components/company/Contact.vue';
import axios from 'axios';
export default {
  name: '',
  data() {
    return {
        form: this.$form.createForm(this, { name: 'coordinated' }),
        applicantType: 0,
    }
  },
  components: {
    Contact
  },
  methods: {
    chooseType(index) {
      this.applicantType = index
      this.form.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.disabled = true
          const data = {...values, applicantType: this.applicantType}
          axios.post('/project-manage/api/intention/info/', data)
          .then(res => {
            if(res.status == 200 && res.data.code == 200) {
              this.$message.success('提交成功');
              this.form.resetFields()
            }
          })
        }
      });
    }
  },
}
</script>
<style scoped lang="less">
  .jetlinks-contact{
    height: 100%;
    .banner{
      width: 100%;
      height: 26.25rem;
      padding: 7.125rem 24.375rem;
      background: url('~assets/images/contact/bg.png') no-repeat center center/100% 100%;
      h1{
        width: 100%;
        position: relative;
        span{
          text-transform: uppercase;
          background: linear-gradient(266.88deg, #BA60F1 26.28%, #0E6EFF 79.94%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-size: 4rem;
        }
        &::after{
          content: '';
          width: 9.5rem;
          height: 5px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #BEC5E5;
        }
      }
      p {
        margin-top: 1rem;
        width: 48.9375rem;
        font-size: 2rem;
        line-height: 2.5rem;
        color: #0B1145;
      }
    }
    .enlist{
      border: 1px solid #D5D6D9;
      border-radius: 4px;
      position: relative;
      padding: 1.25rem 1.75rem;
      &::before{
        content: '';
        width: 100%;
        height: 9px;
        position: absolute;
        background: #0E6EFF;
        left: 0;
        top: 0;
        border-radius: 4px;
      }
      &::after{
        content: '';
        width: 100%;
        height: 9px;
        position: absolute;
        background: #BEC5E5;
        left: 0;
        bottom: 0;
        border-radius: 4px;
      }
      .benefits{
        width: 100%;
        background: #F3F5FB;
        .benefits-item{
          height: 100%;
          padding: 1.25rem 0;
          display: flex;
          align-items: center;
          .check{
            margin-right: 1.25rem;
            width: 1.875rem;
            height: 1.875rem;
            border: 1px solid #9DF2CE;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p{
            font-size: 1.125rem;
            color: #666666
          }
        }
      }
      .form-content{
        width: 60%;
        margin: 1.5rem auto;
        p{
          color: rgba(11, 17, 69, 0.8);
          font-size: 1.125rem;
          text-align: center;
        }
        .ant-form{
          width: 80%;
          margin: 1.5rem auto;
          overflow: auto;
          .service-type{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .type-item{
              cursor: pointer;
              width: 7.5rem;
              height: 6.875rem;
              border-radius: 6px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              h3{
                font-size: 1.125rem;
                font-weight: 400;
                color: #000000;
              }
              &.active{
                border-color: #0E6EFF;
                &::after{
                  content: '';
                  border-top: 1.5rem solid #0E6EFF;
                  border-bottom: 1.5rem solid transparent;
                  border-left: 1.5rem solid transparent;
                  border-right: 1.5rem solid #0E6EFF;
                  position: absolute;
                  right: 0;
                  top: 0;
                  border-top-right-radius: 6px;
                }
              }
            }
          }
        }
      }
    }
  }

  
  :deep(.ant-input){
    height: 3.75rem;
    padding-left: 20px !important;
    position: relative;
  }
  .custom-require{
    :deep(.ant-form-item-children::before){
      content: "*";
      width: 20px;
      height: 20px;
      color: #f5222d;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1;
      position: absolute;
      left: 0.5rem;
      top: 0.1rem;
      z-index: 1;
    }
  }
</style>